.playback {
    margin-top: 1rem;
    padding: 1rem 0;
    background: linear-gradient(115deg, #12063b, #09555c);
}

.review_container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    --grid-height: unset;
}

.review_list {
    list-style: none;
    box-shadow: 0 0 2rem 1rem rgba(0,0,0,0.3);
    border-radius: 0.5rem;
    padding: 1rem 1rem;
    width: 100%;
    margin-left: 0rem;
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill,calc(var(--grid-height)*16/9));
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
    justify-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.review_container .sidebar {
    width: 100%;
    flex: 1 1 auto;
    height: auto;
    margin: 0rem 0 0.5rem 0.5rem;
    min-height: unset;
    max-width:unset;
}

.review_container i {
    
}

.review_container .collapse {
    width:100%;
    flex: 0 0 auto;
}

.review_toggle {
    color: #fff;
    font-size: 1.3rem;
    display: block;
    cursor: pointer;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background: hsla(0,0%,100%,.1);
    border-radius: 0.25rem;
}
    .review_toggle > div{
        transition:transform 0.3s;
        width:100%;
        margin-left:0.75rem;
    }

    .review_toggle i {
        color: #5893e3;
    }

    .review_toggle:hover {
        background: rgba(0,0,0,0.5);
        border-radius: 0.3rem;
    }

.review_toggle > div:hover {
    transform: translateX(0.5rem);
}
    @media (min-width: 576px) {

        .review_container {
            --grid-height: 6rem;
        }
    }

    @media(min-width:768px) {
        .review_container {
            --grid-height: 7rem;
            display: flex;
            flex-flow: row nowrap;
        }

        .review_toggle {
            display: none;
        }

        .review_container .sidebar {
            width: 100%;
            flex: 1 1 auto;
            height: auto;
            margin: 0rem 0 2rem -0.5rem
        }

        .review_list {
            margin-left: 1rem;
            width:70%;
        }

        .review_container .collapse {
            width: 20%;
        }
    }

    @media(min-width:992px) {

        .review_container {
            --grid-height: 8rem;
        }

        .review_list {
            justify-content: space-around;
            grid-column-gap: 0.5rem;
        }
    }

    @media (min-width: 1200px) {
    }


    @media (min-width: 1400px) {
    }
