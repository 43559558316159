
.banner {
    background: linear-gradient(45deg, #004276 0%, #133853 60%);
    color: #fff;
    padding: 2rem 0;
    position: relative;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
}


.banner img{
    position:absolute;
    right:0;
    bottom:0;
    z-index:10;
    width:30%;
}

.banner_container{
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items:center;
}

.carousel-img {
    aspect-ratio:16/9;
    overflow: hidden;
    position: relative;
}

    .carousel-img > img {
        position: absolute;
        right: 0;
        bottom: 0;
    }

.carousel_caption {
    right: unset;
    top: 50% !important;
    left: 50%;
    bottom: unset;
    transform: translate(-50%,-50%);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content:space-between;
}



.banner_title >*{
    font-weight:500;
}

.banner_title>*:not(:last-child){
    margin-bottom:1.3rem;
}



.banner_title {
    text-align: justify;
    flex: 1 1 auto;
    display: inline-flex;
    flex-flow: column nowrap;
    width:100%;
}
    .banner_title h3 {
        font-size: 1rem;
    }

.banner_title h3 {
        font-size: calc(1.1rem + .6vw);
    }

    .banner_title h2{
        font-size:1rem;
    }
.broad_live {
    aspect-ratio: 16/9;
    box-shadow: 0 0 2rem 0.3rem rgba(0,0,0,0.1);
    flex: 1 1 auto;
    width: 100%;
    margin-top: 2rem;
    position:relative;
}

.broad_live iframe{
    width:100%;
    height:100%;
    border-radius:0.25rem;
}



.broad_live > div {
    width: 100%;
    aspect-ratio: 16/9;
}

.stream_live {
    width: unset!important;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background: rgb(173, 50, 38);
    padding: 0 0.5rem;
    border-radius: 0.1rem;
    z-index: 100000;
}

.broad_live video{
    border-radius:0.25rem;
}


.carousel_caption> *{
   
}

.carousel .active {
    border-bottom: unset;
}

.carousel-control-prev, .carousel-control-next {
    width: unset;
}

.register_now {
    text-decoration:none;
    font-size: 1rem;
    font-weight:500;
    color: #fff;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    background: rgba(255,255,255,0.1);
    cursor: pointer;
    text-align: center;
    transition: background 0.6s;
    display:inline-block;
}

    .register_now:hover {
        background: linear-gradient(to right, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.1) 100%)
    }

.topic_area {
    margin: 1rem 0 0 0;
    background: linear-gradient(to right, rgb(0, 4, 40), rgb(0, 78, 146));
    box-shadow: rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
}



.chair_area {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.chair_title {
    font-size: 1.3rem;
    color: #fff;
    margin: 1rem 0 1rem 0;
}



.chair_container {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    --chair_img_width: 10rem;
}

.chair_text_area {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}


.o_area {
    margin-top: 1rem;
    background: linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85));
    box-shadow: rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
}

.learn_more {
    align-self: flex-start;
}

    .learn_more a {
        color: #fff;
        display: block;
        font-weight: 600;
    }

        .learn_more a:hover {
            animation: moveleftright 0.5s ease normal;
            animation-fill-mode: forwards
        }

@keyframes moveleftright {
    0% {
        transform: translateY(0rem);
    }

    100% {
        transform: translateY(0.2rem);
    }
}





.overview_area {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 0 2rem 0;
}

.overview_title {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 1rem 0 0 0;
}

.overview_content, .learn_more {
    color: #fff;
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
}


.carousel_container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.exciting {
    background: linear-gradient(to right, rgba(20, 26, 49, 1) 0%, 24.867724867724867%, rgba(60, 79, 111, 1) 49.735449735449734%, 74.86772486772486%, rgba(170, 176, 192, 1) 100%);
    box-shadow: rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
    padding:1rem 0;
}


.exciting_area{
    display:flex;
    flex-flow:column nowrap;
    justify-content:flex-start;
}

.exciting_title{
    color:#fff;
    font-size:1.5rem;
    font-weight:600;
    margin-bottom:1rem;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
}

.exciting_title>span{
    display:table-cell;
    vertical-align:middle;
    height:2.5rem;
}



.exciting_content {
}



.overview_area {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
}

.exciting {
    margin-top:1rem;
    position: relative;
}

    .exciting_content > div {
        position:relative;
    }

.arrow_container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    border: none;
    opacity: 0.6;
    position: absolute;
    transition: transform .6s;
    
    border-radius: 0.25rem;
}

.arrow_container i{
    font-size:1.8rem;
    color:#fff;
}

.arrow_left {
    top: 0;
    left: 0;
    bottom: calc(var(--poster-height)/2);
    background: linear-gradient(to left, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0.3) 20%);
    box-shadow: rgba(0,0,0,0.1) 0 0 1rem 1rem;
}
.arrow_right {
    right: 0;
    top: 0;
    bottom: calc(var(--poster-height)/2);
    background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0.3) 20%);
    box-shadow: rgba(0,0,0,0.1) 0 0 1rem 1rem;
}



.arrow_container button:hover{
    opacity:1;
}

.arrow_container button svg:hover {
    
    transform:scale(1.2);
}

.exciting, .poster_container, .arrow_container{
    --poster-height:10rem;
}

.h_speakers {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1rem;
    padding:1rem 0;
    background: linear-gradient(115deg, #12063b, #09555c);
    box-shadow: rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
}

.h_speaker_area{
    color:#fff;
}

.h_speaker_title{
    font-size:1.5rem;
}

.h_exhibition {
    background: linear-gradient(to right, #1d2545 0%, 14.950166642665863%, #1D3339 29.900333285331726%, 44.684384763240814%, #1b1f43 59.4684362411499%, 79.73421812057495%, #264749 100%);
    padding: 1rem 0;
    box-shadow:rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
    margin-top:1rem;
}

.h_exhibition_container {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    align-items: flex-start;
    color: #fff;

}

.h_exhibition_container {
    flex:0 5 auto;
}

.h_sponsors {
    margin-top:1rem;
    padding: 1rem 0;
    background: #0d0f18;
    box-shadow: rgba(1, 37, 61, 0.4) 0 0 1rem 0.5rem;
    color:#fff;
}

.h_sponsors_container{
    

}

.home_login{
    position:absolute;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    left:0;
    top:0;
    z-index:10000;
}


@media (min-width: 576px) {
    .carousel_container {
        max-width: 540px;
    }
    .chair_title {
        width: 20%;
    }
}

@media(min-width:768px) {


    .banner_container {
        flex-flow: row nowrap;
        align-items: center;
        justify-content:space-between;
    }

    .carousel-img {
        aspect-ratio: 16/9;
        overflow: hidden;
        position: relative;
    }
     .carousel-img > img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
    }
    .carousel_caption {
        flex-flow: row nowrap;
        align-items: center;
    }

    .banner_title {
        width: 40%;
    }

    .broad_live {
        width: 50%;
        margin-top:0rem;
    }

    .register_now {
        font-size: 1rem;
    }

    .banner_title h3 {
        font-size: calc(1rem + .3vw);
    }

    .banner_title h5 {
        font-size: 1rem;
    }

    .banner_title {
        width: 40%;
    }
    .chair_container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
    }
    .carousel_caption > * {
        
    }


    .chair_title {
        font-size: 1.5rem;
    }

    .carousel_container {
        max-width: 720px;
    }

    .h_exhibition_container {

        flex-flow: row nowrap;
        justify-content: space-between;
        align-items:center;
    }
}

@media(min-width:992px) {

    .register_now{
        font-size:1.2rem;
    }

    .banner_title h3 {
        font-size: calc(1.2rem + .6vw);
    }

    .banner_title h2{
        font-size:1.2rem;
    }

    .banner_title{
        width:40%;
    }

    .broad_live{
        width:55%;
    }

   

    .chair_area {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .chair_container {
        display: flex;
        flex-flow: row nowrap;
    }

    .carousel_container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .carousel_container {
        max-width: 1140px;
    }
}


@media (min-width: 1400px) {
    .carousel_container {
        max-width: 1320px;
    }
}
:root {
    --body-background: linear-gradient(45deg, #01253D 0%, 20.000000298023224%, #4B9DD1 52.78688669204712%, 58.36065709590912%, #005F97 100%)
}