.stream_player{
    position:relative;
}

.stream_live {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background: rgb(173, 50, 38);
    padding: 0 0.5rem;
    border-radius: 0.1rem;
    z-index:100000;
}

.video-js, .vjs-modal-dialog {
    border-radius: 0.25rem;
}