.hctitle_nav {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    width: 100%;
    text-decoration:none;
}
.hctitle {
    padding: 0.2rem 0.5rem 0.2rem 0;
    transition: transform 0.2s;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    color:#fff;
}

.hctitle_arrow{
    margin-top:0.2rem;
    color:#5893e3;
}

.hctitle span {
    height: 2rem;
    line-height: 2rem;
    display:flex;
    align-items:center;
}

.hctitle_nav:hover {
    background: rgba(255,255,255,0.1);
    border-radius: 0.3rem;
}

.hctitle:hover {
    transform: translateX(0.5rem);
}

.hctitle a{
    text-decoration:none;
    color:#fff;
}

.hctitle span:hover{
    color:#5893e3;
}

.t_learnmore{
    display:none;
}



@media(min-width:768px) {


    .t_learnmore {
        text-decoration:none;
        font-size: 0.9rem;
        margin-top: 2rem;
        color: #fff;
        height: 2rem;
        line-height: 2rem;
        border-radius: 0.3rem;
        padding: 0 1rem;
        background: rgba(255,255,255,0.1);
        cursor: pointer;
        text-align: center;
        transition: background 0.6s;
        display:block;
    }


        .t_learnmore:hover {
            background: linear-gradient(to right, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.1) 100%)
        }
    .hctitle_nav:hover {
        background: unset;
        border-radius: unset;
    }
    .hctitle_nav {
        font-size: 1.5rem;
        flex-shrink: 0;
        margin-bottom: 0;
        cursor:default;
        width:unset;
        display:flex;
        flex-flow:column nowrap;
        justify-content:center;
        align-items:flex-start;
    }

    .hctitle_arrow {
        display: none;
    }
}