.chair_wrapper {
    cursor:pointer;

}

.chair_wrapper{
    margin-bottom:1rem;
}


.chair_item_container {
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    width: 100%;
}

.chair_item_container .chair_name{
    font-weight:600;
}


.chair_img_container {
    aspect-ratio: 5/6;
    width: var(--chair_img_width);
    position: relative;
    flex: 0 0 auto;
    overflow: hidden;
    box-shadow: 0 0 1rem 0.5rem rgba(0,0,0,0.1);
    border-radius:0.25rem;
}

.chair_img_container img{
    position:absolute;
    z-index:10;
    width: var(--chair_img_width);
    left:0;
    top:0;
}

.chair_item_container .chair_name{
    margin:0.5rem 0;
}



.chair_hover {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #fff;
    background: rgba(58, 61, 118,0.6);
    backdrop-filter: blur(5px);
}

.chair_hover .chair_img_container img{
    z-index:200;
}

.nohover {
    display: none;
}

.chair_item_container .chair_profile {
    padding: 0 0 0 1rem;
}

.chair_hover .chair_profile {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0.75rem;
    justify-content: flex-start;
    width: calc(var(--chair_img_width)*2.5);
}

.chair_name{
    font-size:1rem;
    font-weight:500;
    margin-bottom:1rem;
}

.chair_desc{
    font-size:0.86rem;
}

.chair_desc p{
    margin-bottom:0.4rem;
}

.chair_hover .chair_name{
    font-size:1rem;
    font-weight:600;
}

.chair_hover .chair_desc{
     font-size:0.86rem;
}


.chair_item_container .chair_desc{
    display:block;
}

.chair_wrapper:hover {
    background: rgba(58, 61, 118,0.6);
    backdrop-filter: blur(5px);
}




@media (min-width:576px){
    .chair_name {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .chair_desc {
        font-size: 1rem;
    }

        .chair_desc p {
            margin-bottom: 0.4rem;
        }
}

@media(min-width:768px) {
    .chair_item_container .chair_desc {
        display: none;
    }

    .chair_item_container {
        display: flex;
        flex-flow: column nowrap;
        flex: 0 0 auto;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        width: var(--chair_img_width);
    }

        .chair_item_container:not(:first-child) {
            margin-left: 1rem;
        }

    .chair_wrapper {
        margin: 0.5rem 0;
    }

        .chair_wrapper:hover {
            background: unset;
            backdrop-filter: unset;
        }
    .chair_name {
        font-size: 1rem;
        white-space:nowrap;
        margin-bottom: 1rem;
    }
}




@media(min-width:992px) {
    

    .chair_wrapper {
        margin: 2rem 0;
    }
}


