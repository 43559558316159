.video_detail {
    color: #fff;
    width: 100%;
    flex:0 0 auto;
}


.video_container {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    overflow: hidden;
}

.video_wrapper {
    border-radius: 0.25rem;
    padding: unset;
    margin-left: unset;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    width: 100%;
}

.video_player {
    width: 100%;
    flex: 0 0 auto;
    margin-bottom:1rem;
}

    .video_player video {
        width: 100%;
        aspect-ratio: 16/9;
    }


.v_list {
    width: 100%;
    position: relative;
}

.video_slist {
    flex: 0 0 auto;
    
    background: rgba(255,255,255,0.1);
    padding: 0rem 0.5rem;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    scrollbar-width: none !important;
    margin-bottom: unset;
    border-radius: 0.25rem;
    list-style: none;
    box-shadow: 0 0 1rem rgba(0,0,0,0.1);
    display: flex;
    flex-flow: row nowrap;
}

.video_container .sidebar {
    margin: unset;
    min-height: unset;
}

.video_slist::-webkit-scrollbar {
    display: none;
}

.video_player > div:first-child {
}

.video_container .video_toggle {
    display: block;
    cursor: pointer;
    
    height:2.5rem;
    display: flex;
    align-items: center;
}

.video_toggle span {
    font-size: 1.3rem;
}

.video_toggle i{
    font-size:1.3rem;
}
.video_toggle > div{
    width:100%;
}

.video_toggle > div:hover {
    transform: translateX(0.5rem);
}

.video_toggle:hover {
    background: rgba(0,0,0,0.5);
    border-radius: 0.25rem;
}

.left_arrow {
    position: absolute;
    font-size: 1.3rem;
    border-radius: 0.25rem 0.25rem 0 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 2rem;
    color: #f1f1f1;
    text-align: center;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor: pointer;
}

    .left_arrow i {
        position: relative;
        top: 40%;
        display: block;
        transition: transform ease 0.3s;
    }

        .left_arrow i:hover {
            transform: scale(1.05) translateX(-0.1rem);
        }

.right_arrow {
    position: absolute;
    font-size: 1.3rem;
    border-radius: 0.25rem 0.25rem 0 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 2rem;
    color: #f1f1f1;
    text-align: center;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor: pointer;
}

    .right_arrow i {
        position: relative;
        top: 40%;
        display: block;
        transition: transform ease 0.3s;
    }



        .right_arrow i:hover {
            color: #fff;
            transform: scale(1.05) translateX(0.1rem);
        }

.v_up_arrow {
    position: absolute;
    font-size: 1.3rem;
    border-radius: 0.25rem 0.25rem 0 0;
    right: 0;
    top: 0;
    height: 2rem;
    width: 100%;
    color: #f1f1f1;
    text-align: center;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor: pointer;
}
    .v_up_arrow i {
        display: inline-block;
        transition: transform ease 0.3s;
    }

    .v_up_arrow i:hover{
        transform:translateY(-0.1rem) scale(1.05);
        color:#fff;
    } 

.v_down_arrow {
    position: absolute;
    font-size: 1.3rem;
    border-radius: 0.25rem 0.25rem 0 0;
    right: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    color: #f1f1f1;
    text-align: center;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor: pointer;
}

    .v_down_arrow i {
        display: inline-block;
        transition: transform ease 0.3s;
    }

.v_down_arrow i:hover {
    transform: translateY(0.1rem) scale(1.05);
    color: #fff;
}


@media(min-width:768px) {
    .video_container .video_toggle {
        display: none;
    }

    .video_container {
        flex-flow: row nowrap;
    }

    .video_wrapper {
        margin-left: 0rem;
        padding: 0rem 0rem 1rem 0rem;
        width: 80%;
    }
    .video_detail {
        color: #fff;
        width: 80%;
        flex: 0 0 auto;
    }
}

@media(min-width:992px) {
    .video_wrapper {
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .video_player {
        width: 65%;
    }

    .v_list {
        width: 30%;
    }

    .video_slist {
        flex-flow: column nowrap;
        overflow-y: scroll;
        scroll-snap-type: y proximity;
        overflow-y: hidden;
    }

   
    
}
