.htitle_nav{
    display:block;
    margin-bottom:1rem;
}
.htitle{
    font-size:1.2rem;
    color:#fff;
   display:flex;
   padding:0.2rem 0.5rem 0.2rem 0;
   justify-content:flex-start;
   align-items:center;
   transition:transform 0.2s;
}

.htitle_nav {
    text-decoration: none;
}

.htitle span {
    display:flex;
    align-items:center;
    height: 2rem;
    line-height: 2rem;
}

    .htitle_nav:hover {
        background: rgba(255,255,255,0.1); 
        border-radius:0.25rem;
    }
    .htitle span:hover {
        color: #5893e3;
    }

    .htitle:hover{
        transform:translateX(0.5rem);
    }

    .htitle svg {
        color: #5893e3;
        margin-top:0.2rem;
    }


@media(min-width:768px){
    .htitle {
        font-size: 1.5rem;
        margin-bottom: 0.3rem;
    }
}