.agenda_area {
    background: linear-gradient(115deg, #12063b, #09555c);
    color: #fff;
    padding: 1rem 0;
}

.agenda_title{
    text-align:center;
}

.agenda_main{
    font-size:1.3rem;
}

.agenda_header{
    margin-bottom:unset;
    margin-left:unset;
    padding-left:unset;
    display:flex;
    flex-flow:row nowrap;
    font-size:1.2rem;
    list-style:none; 
    justify-content:flex-start;
    color:rgba(255,255,255,0.8);
}

.agenda_header li{
    cursor:pointer;
    padding:0.5rem 1.5rem;

}

    .agenda_header li span{
        transition:transform ease-in 0.2s;
        display:block;
        min-width:10rem;
    }

 

    .agenda_header li:hover {
        background: rgba(255,255,255,0.3);
        color:#fff;
       
    }

    .agenda_header li span:hover{
        transform:translateX(.35rem);
    }

    .agenda_content{
        background:rgba(255,255,255,0.1);
        backdrop-filter:blur(5px);
        border-radius:0.25rem;
        
    }

.agenda_detail {
    background: #fff;
    color: rgb(50, 54, 86);
    border-radius:0 0 0.25rem 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom:1rem;
}

.agenda_session_header{
    font-size:1.1rem;
    font-weight:600;
    margin-top:0.5rem;
}

.agenda_sessionitem {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.25rem;
    padding: 0.5rem;
}

.agenda_sessionitem:nth-child(2n){
    background:rgba(0,0,0,0.03);
    border-radius:0.25rem;
}

.agenda_items_container {

    
}

.agenda_date_active {
    background: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgb(34, 47, 74);
}

.sessionItem_time {
    width: 10%;
    min-width: 6rem;
    flex: 0 0 auto;
}

.agenda_header span{
    text-align:center;
}

.sessionItem_title_speaker {
    width: 90%;
    padding: 0 1rem;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.sessionItem_speaker {
    color: rgb(85, 132, 192);
    font-weight:600;
}

