.about_area {
    background: linear-gradient(115deg, #12063b, #09555c);
}
.about_us {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 2rem 0;
}

.about_banner{
    aspect-ratio:16/9;
    overflow:hidden;
    position:relative;
    flex:0 0 auto;
    margin-bottom:-1rem;
}

.about_banner img{
    width:100%;
}

.about_desc {
    flex: 0 0 auto;
    padding: 1rem;
    background: rgba(255,255,255,0.9);
    box-shadow: 0 0 3rem 0.1rem rgba(0,0,0,0.1);
    text-align: justify;
    border-radius: 0.25rem;
}

.about_chair{
    width:30%;
    flex: 0 0 auto;
}

.about_chair_container{
    width:10rem;
    position:relative;
    
}

 .about_chair_container img {
    width: 100%;
    position:absolute;
    top:0;
    left:0;
}

.about_desc span{
    }

.whoweare {
    padding-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

.about_chairmen{
    font-size:1.5rem;
    font-weight:600;
    text-align:center;
    margin-bottom:1rem;
}

.about_us .chair_profile{
    color:#000000;
}
.about_us .chair_hover {
    background: rgba(0,0,0,0.5) !important;
    color:#fff;
}

.about_us .chair_hover .chair_profile{
    color:#fff;
}

.about_us .chair_wrapper:hover {
    background: rgba(0,0,0,0.1)!important;
}

@media(min-width:768px){
    .about_us .chair_profile {
        
    }

    

}

@media(min-width:992px) {
    .about_chairmen{
        margin-bottom:0rem;
    }
    
}


@media(min-width:1200px) {
    .about_us {
        display: flex;
        align-items: center;
        margin: 2rem 0;
    }

    .about_desc {
        z-index: 100;
        flex: 0 0 auto;
        padding: 1rem;
        background: rgba(255,255,255,0.9);
        box-shadow: 0 0 3rem 0.1rem rgba(0,0,0,0.1);
        text-align: justify;
    }

    .about_banner {
        aspect-ratio: 16/9;
        overflow: hidden;
        position: relative;
        flex: 0 0 auto;
    }

    
}