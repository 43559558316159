.speakers {
    min-height: 30vh;
    padding: 1rem 0;
    background: linear-gradient(115deg, #12063b, #09555c);
}

.s_speakers{
    display:flex;
    flex-flow:column nowrap;
    margin-left:0.5rem;
    color:#fff;
    width:100%;
}

.speakers_container {
    margin-top: 1rem;
    border-radius: 0.25rem;
    display: flex;
    flex-flow: row nowrap;
}

.speaker_detail>iframe{
    aspect-ratio:16/9;
}

.speakers_list {
    width:100%;
    display: flex;
    flex-flow: column nowrap;
    margin: 0rem 0rem 1.5rem 0rem;
   
    position:relative;
}

.speaker_container {
    overflow-y: scroll;
    height: 80vh;
    scroll-behavior: smooth;
    padding: unset;
    -webkit-scroll-snap-type: x proximity;
    -ms-scroll-snap-type: proximity proximity;
    scroll-snap-type: y proximity;
    scrollbar-width: none !important;
    margin-bottom: unset;
    position: sticky;
    padding: 0rem 0rem;
    border-radius: 0.25rem;
}

.speaker_container::-webkit-scrollbar{
    display:none;
}

.speakers_arrow_container{
    position:absolute;
    z-index:1000;
    color:#fff;
    width:100%;
    background:rgba(0,0,0,0.5);
    height:2rem;
    top:0;
    left:0;
    height:100vh;
    
}

.top_arrow {
    position: absolute;
    font-size:1.3rem;
    border-radius:0.25rem 0.25rem 0 0;
    top: 0;
    left: 0;
    height: 2rem;
    color: #f1f1f1;
    text-align: center;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor:pointer;
    
}

    .top_arrow i {
        display: inline-block;
        transition: transform ease-in-out 0.3s,color ease-in-out 0.3s;
    }

.top_arrow i:hover{
    transform:translateY(-0.15rem);
    color:#fff;
}

.down_arrow {
    position: absolute;
    font-size: 1.3rem;
    border-radius: 0 0 0.25rem 0.25rem;
    bottom: 0;
    left: 0;
    height: 2rem;
    color: #f1f1f1;
    text-align: center;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.3) 0 55%, rgba(0,0,0,0.1) 100%);
    cursor: pointer;
    
}
    .down_arrow i {
        display:inline-block;
        transition: transform ease-in-out 0.3s, color ease-in-out 0.3s;
    }
.down_arrow i:hover{
    transform:translateY(0.15rem);
    color:#fff;
}

.speaker_toggle {
    width: 100%;
    display: block;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    color: #fff;
    background: hsla(0,0%,100%,.1);
}

    .speaker_toggle i {
        color: #5893e3;
    }

.speaker_toggle>div {
    transition: transform 0.2s;
}

    .speaker_toggle > div:hover {
        transform: translateX(0.5rem);
    }
    .speaker_toggle:hover {
        background: rgba(0,0,0,0.5);
        border-radius: 0.25rem;
    }



@media(min-width:768px) {
    .s_speakers {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
    }

    .speaker_toggle{
        display:none;
    }


}
