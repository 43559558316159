

.chevron {
    transition: transform linear 0.2s;
    cursor: pointer;
    transform-origin:50% 50%;
}


.chevron-down {
    transform: rotate(180deg);
}

.intro-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    border-left: 0.25rem solid #561910;
    margin-top: 2rem;
}

.intro-item {
    height: 0;
    box-shadow: none;
    padding: 0;
    opacity: 0;
    display:none;
    transition: height 0.2s, opacity 0.2s,display 0.2s;
}

.intro-item p{
    text-align:justify!important;
}

.intro-item p:last-child{
    margin-bottom:0;
}

.intro-show {
    opacity: 1 !important;
    height: 100% !important;
    padding: 1rem !important;
    display:block!important;
    box-shadow: rgba(0,0,0,0.1) 0rem 0rem 0.75rem 0.15rem !important;
}