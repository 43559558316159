.sponsor_img_container {
    position:relative;
    overflow: hidden;
    aspect-ratio:3/1;
    background:#fff;
    padding:1rem 2rem;
    border-radius:0.25rem;
    display:block;
    box-shadow:rgba(0,0,0,0.1) 0 0 3rem 1rem;
}

.sponsors_container li:hover {
    opacity:0.8;
}



.sponsor_img_container img {
    height: var(--sponsor-height);
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%,-50%) scale(1);
    transition: transform 0.6s;
    z-index:1000;
}

    .sponsor_img_container img:hover {
        transform: translate(-50%,-50%) scale(1.1);
    }