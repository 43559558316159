.home_login {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    z-index: 1000;
    color:#000;
}

.login_container form{
    width:100%;
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
}

.home_login h3 {
    font-size: calc(1.2rem + .6vw);
}

.home_login h5 {
    font-size: 1.1rem;
}

.login_container form label{
    
}

.login_container .form-group {
    width: 100%;
}

.login_container form form-group{
    margin-bottom:2rem;
}

.login_container button{
    margin-top:0.5rem;
}

.home_login >.container{
    height:100%;
    display:flex;
    flex-flow:row nowrap;
    justify-content:center;
    align-items:center;
}

.mask_form{
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items:center;
}

.mask_register {
    margin-top: 1rem;
    width: 100%;
    padding:.375rem .75rem
}

.email_feedback {
    color: var( --bs-form-invalid-color);
}

.home_login .loading{
    margin-bottom:unset;
    justify-content:center;
}

.login_close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 1001;
    color: #fff;
    font-size: 2.5rem;
    cursor: pointer;
}

    .login_close:hover {
        opacity: 0.8;
    }

    .login_container{
        background:rgba(255,255,255,0.9);
        width:100%;
        border-radius:0.25rem;
        padding:1rem 2rem;
        display:flex;
        flex-flow:column nowrap;
        justify-content:center;
    }

    .mask_title{
        margin-bottom:0.5rem;
        text-align:center;
    }

@media (min-width: 576px) {
    .login_container form {
        width:80%;
    }

    .mask_register{
        width:80%;
    }

    .login_container .form-group {
        width: 100%;
    }
    .mask_register {
        margin-top: 2rem;
    }
    .login_container button {
        margin-top: 1rem;
    }

    .home_login h3 {
        font-size: calc(1.3rem + .6vw);
    }

    .home_login h5 {
        font-size: 1.25rem;
    }


    .mask_title {
        margin-bottom: 1rem;
    }
}