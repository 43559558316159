.sponsors_container {
    list-style: none;
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(3,1fr);
    justify-content: flex-start;
    padding: unset;
    --sponsor-height: 2rem;
    
}




@media (min-width: 576px) {
    .sponsor_container {
        --sponsor-height: 2.5rem; 
    }
}

@media(min-width:768px){
    .sponsors_container {
        --sponsor-height: 3.3rem;
        grid-template-columns: repeat(4,1fr);
    }

    
}

