.h_banner {
    width: 100%;
    position: relative;
    color: #fff;
    background-image: url("./sco_kv.jpg");
    background-size:cover;


}

authenticate_container{
    
}
.auth_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    position: relative;
}

.auth_logo img{
    width:60%;
    max-width:20rem;
}

.invalid-email {
    color: var(--bs-form-invalid-color);
    font-size: .875em;
}

.h_blur {
    padding:1rem;
    opacity:0.8;
    background:#fff;
    box-shadow: 0 4px 4px rgba(0,0,0,.1), 0 2px 80px rgba(0,0,0,.2);
}

.acrylic {
    position: relative;

}

    .acrylic::before {
        content: "";
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px);
        border-radius:0.25rem;
    }

    .acrylic::after {
        content: "";
        position: absolute;
        top: -0.25rem;
        left: -0.25rem;
        right: -0.25rem;
        bottom: -0.25rem;
        background-color: rgba(255, 255, 255, 0.1);
    }

.auth_indicator{
    margin:3rem 0 2rem 0;
    text-align:center;
    display:flex;
    flex-flow:column nowrap;
}

.auth_indicator p{
    display:block;
}

.auth_indicator p:first-child{
    font-size:1.3rem;
    font-weight:500;
}

.authenter{
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items:center;
}

.loading .container {
    display: flex;
    justify-content:flex-end;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 10rem;
}

.loading .container>div:first-child{
    margin-bottom:1.5rem;
    font-size:2rem;
    font-weight:600;
}

.authenter .form_area{
    display:flex;
    flex-flow:column nowrap;
    justify-content:flex-start;
    background:rgba(255,255,255,0.1);
    padding:1.5rem 3rem;
    border-radius:0.5rem;
    backdrop-filter:blur(10px);
    margin:0 1.5rem;
    width:100%;
}

.authenter .form_area form{
    display:flex;
    flex-flow:column nowrap;
}

.form-group, .form-check {
    margin-bottom: 1rem!important;
}

.btn-primary{
    min-width:5rem;
    width:30%;
}

.form_area {
    margin-bottom: 3rem!important;
}

.form-check a {
    color:#fff;
    font-weight:600;
}

.phone {
    
}

.phone_container{
    display:flex;
    flex-flow:row nowrap;
    justify-content:space-between;
    gap:1rem;
}

.phone_container>div:nth-child(2){
    flex:0 1 auto;
}

    .form_area select {
        
        -webkit-appearance: auto;
        /*webkit browsers */
        -moz-appearance:auto;
        /*Firefox */
        appearance: auto;
    }

.form_area label{
    white-space:nowrap;
}


.interest {
    padding-top: calc(.375rem + var(--bs-border-width));
    padding-bottom: calc(.375rem + var(--bs-border-width));
    margin-bottom: 0 !important;
    line-height: 1.5;
}

.interest>label{
    padding-top:0rem!important;
}

.privacy{
    margin-top:1rem;
}

.visitor_note{
    text-align:justify;
    word-break:break-word;
    display:inline-block;
}

.visitor_note p:first-child::before{
    content:'*';
    color:red;
   
}

.nondisplay{
    display:none!important;
}

.region > label:not(:first-child) {
    text-align: left;
    margin-top: 1rem;
}

.country_row{
    flex-flow:row wrap;
}

.country_row .col-sm-3{
    min-width:10rem;
}
.country_row .col-sm-9{
    flex:0 3 auto!important;
}

.form-check-input.is-invalid ~ .form-check-label a, .was-validated .form-check-input:invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color)!important;
}

.invalid_email ~ .invalid-email {
    display: block !important;
}


.loading {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    color:#fff;
    font-size:1.5rem;
}

.loader{
    border: 0.5rem solid #f3f3f3; /* Light grey */
    border-top: 0.5rem solid #3498db; /* Blue */
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    animation: spin 2s linear infinite;
    margin:1rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



@media (min-width: 576px) {
    .country_row {
        flex-flow: row nowrap !important;
    }
    .authenter .form_area {
        width: 95%;
        margin: 0 2rem;
    }
    .region > label:not(:first-child) {
        text-align: right;
        margin-top: 0;
    }
    .region > div:not(:last-child){
        margin-bottom:0;
    }
    .phone {
        margin-top: calc(-1 * var(--bs-gutter-y))!important;
    }
}

@media (min-width: 768px) {
    
    .authenter .form_area {
        width: 90%;
    }
}

@media (min-width: 992px) {
    .authenter .form_area {
        width: 60%;
        margin: 0 3rem;
    }
}