.exhibition_center {
    aspect-ratio: 16/9;
    border-radius: .3rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

    .exhibition_center img {
        aspect-ratio: 16/9;
        transition: -webkit-transform .6s;
        transition: transform .6s;
        transition: transform .6s,-webkit-transform .6s;
        width: 100%;
    }

        .exhibition_center img:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }



    @media (min-width: 768px) {

        .exhibition_center {
            margin-left: 1.5rem;
            width: 70%;
        }
    }