.h_speaker{
    display:flex;
    transition:transform 0.6s;
    --scale-speaker:2;
    cursor:pointer;
}

.h_speaker_nhov {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

h_speaker_hov {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index:15;
}









.h_crop_speaker {
    height: calc(var(--speaker-width)/3*4);
    border-radius: 0.25rem;
    position: relative;
    min-width:var(--speaker-width);
    aspect-ratio: 3/4;
    overflow:hidden;
    display:block;
}



.h_crop_speaker img{
    position:absolute;
    height:calc(var(--speaker-width)/3*4);
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.h_speaker .popout {
    display: flex;
    flex-flow: row nowrap;
    text-decoration: none;
    color: #fff;
}

.h_speaker .popout .h_speaker_descrip{
    display:flex;
    flex-flow:column nowrap;
    justify-content:space-between;
    flex:1 1 auto;
}

.popout .h_speaker_descrip {
    padding: 0.5rem;
    height: calc(var(--speaker-width)/3*4);

}



.popout .h_speaker_name{
    font-weight:600;
    margin-top:unset;
}

.popout .h_instit{
    
}

.popout .hp_desc {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    text-align: justify;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    max-height:55%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:4;
    line-clamp:4;
}

.popout a{
    color:#fff;
    display:inline-block;
}
.popout a i{
   font-weight:700;
}

.popout .readmore {
    font-size: 0.8rem;
    color: #fff;
    font-weight: 600;
    display: block;
    margin-top: 0.5rem;
    transition: transform 0.3s;
    border-radius: 0.25rem;
    padding: 0.25rem 1rem 0.25rem 0rem;
}

.popout a:hover{
    opacity:0.9;
    transform:translateX(0.5rem);
}
.h_speaker a {
    text-decoration: none;
}

.h_speaker_descrip {
    display: flex;
    flex-flow: column nowrap;
    width: var(--speaker-width);
    color: #fff;
    
}

.h_speaker_name {
    margin-top: 1rem;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}
.h_center{
    text-align:center;
    word-break:break-word;
    -ms-hyphens:auto;
    -moz-hyphens:auto;
    hyphens:auto;
}

.h_instit{
    margin-top:0.5rem;
    font-size:0.9rem;
    opacity:0.9;
}

.h_last_op {
    text-align-last: left;
}

.h_speaker_prof{
    display:none;
}

@media(min-width:992px) {

    .h_speaker_name {
        margin-top: 1rem;
        -webkit-line-clamp:2;
        line-clamp:2;
    }

    .popout .hp_desc {
        -webkit-line-clamp: 5;
        line-clamp: 5;
    }
}