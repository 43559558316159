.exciting_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x:scroll;
    -webkit-scroll-snap-type: x proximity;
    -ms-scroll-snap-type: proximity proximity;
    scroll-snap-type: x proximity;
    justify-content: flex-start;
    list-style: none;
    scroll-behavior: smooth;
    padding:unset!important;
    position:relative;
    scrollbar-width:none;
}

    .exciting_container a {
        text-decoration: none;
        color: rgb(29, 33, 50);
        width:100%;
    }
    .exciting_container::-webkit-scrollbar{
        display:none;
    }

    .exciting_container li {
        flex: 0 0 calc(var(--poster-height)*16/9);
        list-style: none;
        cursor: pointer;
        margin-right: 1rem;
        display: inline-grid;
        --scale-ratio: 1.5;
    }


.li_item {
    width: 70%;
    
    
}


.poster_container {
    overflow: hidden;
    margin-right: 0 !important;
    position: relative;
    height: var(--poster-height);
    aspect-ratio: 16/9;
    border-radius: 0.25rem 0.25rem 0 0;
}

.exciting_container img{
    aspect-ratio: 16/9;
    border-radius: 0.25rem 0.25rem 0 0;
}



.li_item:hover{
    opacity:0.8;
}


.exciting_container img {
    position:absolute;
    height:100%;
    left:0%;
    top:0;
    transition:transform ease-in-out 0.6s;
}

.exciting_container img:hover{
    transform:scale(1.05);
}

.li_item {
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0.5rem rgba(0,0,0,0.5);
}

.li_item section{
    
    text-align:justify;
    padding: 0 0.5rem 0.5rem 0.5rem;
}

.speaker_intro{
   width:calc(var(--poster-height)*16/9);
}

.speaker_intro section>div {
    margin-top:0.5rem;
}

.play_title {
    font-weight: 600;
}

.clamp_ecllipse {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.play_speaker, .speaker_insti {
    font-size: 0.87rem;
    color: #333;
}


@media (min-width: 576px) {
    .li_item {
        width: 48%;
    }
}

@media(min-width:768px) {

    .li_item {
        width: 43%;
    }
}

@media(min-width:992px) {
    .li_item{
        width:32%;
    }
}

@media(min-width:1200px) {
    .li_item {
        width: 23%;
    }
}




