
.playvideo{
    border-radius:0.5rem;
    overflow:hidden;
    cursor:pointer;
    width:100%;
    text-decoration:none;
    color:#fff;
}

    .playvideo:hover {
        opacity: 0.9;
        box-shadow: 0 0 3rem 0.5rem rgba(0,0,0,0.1);
    }

.video_poster {
    position: relative;
    height: var(--grid-height);
    overflow:hidden;
    aspect-ratio: 16/9;
}

    .video_poster img {
        aspect-ratio: 16/9;
        position: absolute;
        width: 100%;
        top: 0;
        left:0;
        
      
    }

.video_poster img:hover{
    
    
}


.video_desc{
    margin:0.5rem 0 0rem 0;
    padding:0 0.5rem;
    text-align:justify;
    -webkit-hyphens:auto;
    -moz-hyphens:auto;
    hyphens:auto;
    
}

.video_title {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow:hidden;
    font-size:1rem;
}

.video_author {
    font-size: 0.85rem;
    opacity: 0.9;
    margin: 0.5rem 0;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.video_occupation {
    font-size: 0.85rem;
    opacity: 0.9;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.play_video {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5rem;
    transition:transform 0.3s ease;
    transform: translate(-50%,-50%) scale(2);
}

.play_video:hover{
    box-shadow:0 0 3rem 0.5rem rgba(0,0,0,0.1);
    transform: translate(-50%,-50%) scale(2.5);
}

.play_video i::before{
    background:rgba(0,0,0,0.6);
    border-radius:50%;
}

    .play_video i {
       
       
        color: #fff;
    }


@media (min-width: 576px) {
    .video_title {
        font-size: 0.9rem;
    }

    .video_author {
        font-size: 0.75rem;
    }

    .video_occupation {
        font-size: 0.75rem;
    }
}

@media(min-width:768px) {
    .video_title{
        font-size:0.9rem;
    }
    .video_author{
        font-size:0.75rem;
    }
    .video_occupation{
        font-size:0.75rem;
    }
}

@media(min-width:992px) {
    .video_title {
        font-size: 1rem;
    }

    .video_author {
        font-size: 0.85rem;
    }

    .video_occupation {
        font-size: 0.85rem;
    }
}

@media (min-width: 1200px) {
}


@media (min-width: 1400px) {
}
