.video_slist .nav-item:not(:first-child){
    
    
}

.video_slist .nav-item{
    width:10rem;
    flex:0 0 auto;
}

.video_slist .nav-item:hover {
    background: rgba(0,0,0,0.3);
    opacity: 0.9;
}

.video_slistactive {
    background: rgba(0,0,0,0.3);
}

.video_slist a {
    text-decoration: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center
}

.video_slist li:not(:first-child){
    margin-top:unset;
    margin-left:0.5rem;
}

.v_crop{
    width:100%;
    aspect-ratio:16/9;
    position:relative;
}

.v_crop img{
    width:100%;
    top:0;
    left:0;
    position:relative;
}
.v_title{
    width:100%;
}
.v_title span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow:hidden;
    padding-left: 0.5rem;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-size: 0.8rem;
}

@media(min-width:992px){
    .video_slist li:not(:first-child) {
        margin-left:unset;
        margin-top: 0.5rem;
    }
    .video_slist .nav-item{
    width:100%;
}

}

@media (min-width: 1200px) {
    .video_slist a{
        flex-flow:row nowrap;  
    }
    .v_crop{
        width:50%;
    }
    .v_title{
        width:50%;
    }
}