a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.nav-logo {
    background-color: #25272f;
    display: flex;
    margin-right: 0 !important;
    height:4rem;
    align-items:center;
}


.nav-logo .container{
    display: flex;
    justify-content:space-between;
    
}

.nav-logo .nav-link{
    display:block;
    width:50%;
    padding:0.5rem 0;
    max-width:14rem;
    min-width:7rem;
}


.nav-logo img{
    width:70%;
}

.navbar-collapse{
    width:100%;
}
.navbar-toggler{
    appearance:none;
    -webkit-appearance:none;
    border:unset;
    padding-left:0 0.5rem;
    border-radius:0.25rem;
}

.navbar-toggler i{
    font-size:2rem;
    transition:transform 0.3s,opacity 0.3s;
}

.navbar-toggler i:hover{
    opacity:0.9;
    transform:scale(1.05);
    
}

.navbar-toggler:focus{
    box-shadow:0 0 0.3rem 0.3rem rgba(0,0,0,0.1);
    
}

.navbar-nav {
    width: 100%;
    justify-content: space-between;
}

.navbar-nav>div:first-child{
    width:50%;
    display:flex;
    justify-content:space-between;
}

.nav-login{
    display:flex;
    justify-content:flex-end;
    width:50%;
}

.dropdown-menu {
    width: 15rem;
    padding: 0;
    transform: translateY(2.6rem)!important;
}

.nav-link{
    
}

.navbar-nav .nav-item{
    padding:0.25rem 0;
}



.active {
    
}


.navbar {
    padding: 0 !important;
}

.navbar-toggler{
    margin:0.25rem 0;
}

.dropdown-menu .nav-link {
    border-bottom: none;
}

.dropdown-menu .nav-link:hover {
    border-bottom: none;
    background-color: #efefef;
}

.min-submenu{
    display:block!important;
    width:100%;
    padding-left:1rem;
}

.dropdown-menu .active {
    background-color: #e1e1e1!important;
}

.navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-collapse{
    flex-basis:auto;
}

.submenu {
    background: var(--custom-bg-color);
    padding: 1rem 0;
}

.navbar-nav .nav-item:hover {
    background: var(--custom-hover);
}



.submenu-title a {
    width: 100%;
    max-width: 15rem;
    padding: 0.5rem;
    transition: background 0.5s;
    border-radius: 0.25rem;
    border: none;
}

    .submenu-title a:not(first-child) {
        margin: 0.5rem 0;
    }


    .submenu-title a:hover {
        background: var(--custom-hover);
    }

.submenu-title .active{
    border:none;
    background:var(--custom-active)!important;
}

.second {
    position: absolute;
    z-index: 1000;
}



@media (min-width: 576px) {
    .navbar-nav .active {
        border-bottom: 0.2rem solid #243850;
        color: #7b2316;
        background: none !important;
        border-left: none;
    }

    .nav-link:hover {
        /* border-bottom: 0.2rem solid #7b2316; */
    }

  

    .submenu-title a {
        width: 100%;
    }

    .second {
        position: relative;
    }

}

@media(min-width:768px){
    .nav-logo .nav-link {
       
        width: 35%;
       
    }
}

.bg-close {
    background: var(--custom-bg-color);
    padding: 0.5rem 0;
}

.bg-close button{
    padding-left:1rem;
}

@media (min-width: 992px) {

    .nav-logo .nav-link {
        width: 30%;
    }
    .navbar-nav {
        width: 100%;
        justify-content: flex-start;
    }
        .navbar-nav .nav-item:not(:first-child) {
            margin-left: 2rem;
        }
}

@media (min-width: 576px){
    
}
:root {
    --custom-bg-color: #EDEFF2;
    --custom-hover: #eeeff2;
    --custom-active: #e1e2e5;
}
