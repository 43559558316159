footer {
    background-color: #343434;
    color: #eee;
    font-size: 0.9rem;
    padding: 1rem;

}

    footer .copyright, .address-detail {
        color: #d1d1d1;
    }



.footer-logo {
    max-width: 15rem;
    
    text-align:center;
    box-shadow: rgba(0,0,0,0.3) 0 0 5rem 1rem;
}

footer p {
    margin-bottom:0.5rem!important;
}


.footer-logo img {
    width: 100%;
}

.footer_container{
    display:flex;
    flex-flow:column nowrap;
    justify-content:space-between;
    align-items:flex-start;
    justify-items:flex-start;

}

    .footer_container > div {
        min-width: 16rem;
        margin:0.5rem 0;
    }

.address-header{
    font-size:0.9rem;
}

.wechat_logo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width:100%;
}




.wechat_logo>div{
    position:relative;
    
    width:7rem;
    overflow:hidden;
    aspect-ratio:1/1;
    box-shadow:rgba(0,0,0,0.1) 0 0 3rem 1rem;
}

.wechat_logo>div:not(last-child){
    margin:1rem 2rem 1rem 0;
}

.wechat_logo>div img{
    position:absolute;
    top:0;
    left:0;
    width:7rem;
}

footer .container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    justify-items:flex-start;
}

.footer_container > div:last-child{
    margin-bottom:unset;
}

.change_lng{
    width:100%;
    display:flex;
    flex-flow:row nowrap;
    color:#d1d1d1;
    justify-content:flex-start;
    cursor:pointer;
}

.change_lng a{
    text-decoration:none;
    color:#d1d1d1;
}

.change_lng a:hover{
    color:#fff;
}

@media(min-width:768px) {
    .wechat_logo{
        justify-content:flex-end;
    }
    .change_lng {
        justify-content: flex-end;
    }
    footer .container {
        display: grid;
        grid-template-columns: 3fr 1fr;
    }

    .wechat_logo > div:not(last-child) {
        margin: 0rem 0 1rem 2rem;
    }
}