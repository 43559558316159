.h_speaker_container {
    display: grid;
    grid-template-columns: repeat(3,var(--speaker-width));
    grid-column-gap:0.5rem;
    justify-items: start;
    align-items: start;
    padding: 0 !important;
    justify-content: space-between;
    grid-row-gap: 1.5rem;
}

.a_row {
    grid-auto-rows: calc(var(--speaker-width)*2);
}

.h_speaker_container {
    --speaker-width: 8rem;
    position: relative;
    margin-bottom: unset;
}

@media (min-width: 576px) {
    .h_speaker_container {
        grid-template-columns: repeat(3,var(--speaker-width));
    }
}

@media(min-width:768px) {

    .h_speaker_container {
        grid-template-columns: repeat(4,var(--speaker-width));
    }
}

@media(min-width:992px) {
    .h_speaker_container {
        --speaker-width: 10rem;
        grid-template-columns: repeat(5,var(--speaker-width));
    }
}
@media (min-width: 1200px) {
    .h_speaker_container {
        grid-template-columns: repeat(6,var(--speaker-width));
    }
}


@media (min-width: 1400px) {
    
}