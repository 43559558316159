.sidebar {
    margin: 0rem 0  2rem -0.5rem ;
    max-width: 10rem;
    position: sticky;
    position: -webkit-sticky;
    min-height: 50vh;
}
    .sidebar ul li{
        position:relative;
    }

    .sidebar ul .nav-item {
        margin-left: 0rem !important;
        padding: 0.5rem 0.5rem;
        cursor: pointer;
        color: #fff;
        border-radius: 0.5rem;
        font-size: 1.2rem;
    }

    .sidebar .nav-item:hover {
        background-color: rgba(39, 70, 113,0.9);
        box-shadow: rgba(0,0,0,0.2);
    }

    .sidebar .navitemactive {
        background-color: hsla(0,0%,100%,.1);
        box-shadow: rgba(0,0,0,0.2);
    }

    .sidebar .nav-item a{
        text-decoration:none;
        color:#fff;
        padding:0.5rem 0.5rem
    }

    .sidebar .nav-item{
        margin-bottom:0.5rem;
    }

    .sideactive::before {
        content: "";
        position: absolute;
        border-radius: 0.5rem;
        transform: translateY(-50%);
        left: 0;
        height: 1.5rem;
        top: 50%;
        animation: 0.3s ease-in-out shrinkin;
        width: 0.2rem;
        display: block;
        background: rgb(76, 194, 255);
    }

    .sidebar .nav-item::before {
        
        
    }

    @keyframes shrinkin{
        from{
            height:2.5rem;

        }
        to{
            height:1.5rem;
        }
    }

@media(min-width:768px){
    .sidebar .nav-item a {
        padding: 0.5rem 1rem;
    }
}
