.start_page{
    width:100%;
    height:100vh;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
}

.start_page img{
    width:39%;
}