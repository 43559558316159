.mail_header {
    background: linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85));
    padding: 2rem 2rem;
    width: 100%;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.email_confirm p {
    font-size: 1.5rem;
}

.email_confirm p span {
    font-size: 1.2rem;
}

.click_here{
    color:#fff;
    font-weight:500;
}