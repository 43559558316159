.layout {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 100vh;
}


.popout {
    position: absolute;
    z-index: 100;
    
    background: rgba(19, 14, 55,0.3);
    border-radius: 0.25rem;
    backdrop-filter: blur(10px);
    animation: vh_fadein 0.3s;
    box-shadow: 0 4px 4px rgba(0,0,0,.1), 0 2px 80px rgba(0,0,0,.2);
}

.vrhc{
    
}

.none{
    display:none;
}

.play_icon {
    position: absolute;
    width: var(--play-iconheight);
    height: var(--play-iconheight);
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    
}

.play_icon a{
    color:unset;
    text-decoration:none;
}

.play_icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    --play-iconheight: 4rem;
    line-height: var(--play-iconheight);
    transform: translate(-50%,-50%);
    font-size: var(--play-iconheight);
    border-radius: 50%;
    transition: opacity 0.3s, transform 0.3s;
    display:block;

    
}

.play_icon i::before{
    background:rgba(0,0,0,0.6);
    border-radius:50%;
    display:block;
}

.play_icon:hover {
    

}

    .play_icon i:hover {
        transform: scale(1.05) translate(-50%,-50%);
        opacity: 0.9;
        box-shadow: 0 0 1rem rgba(0,0,0,0.5);
    }






@keyframes vh_fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}