.speaker_detail {
    margin: 0rem 0 1.5rem 0rem;
    border-radius: 0.25rem;
    display: flex;
    width:100%;
    flex-flow: column nowrap;
    padding: 0rem 0rem;
    flex: 1 3 auto;
    padding-bottom: 2rem;
    overflow: hidden;
    min-width: 20rem;
   
}


.sintro_container{
    margin-top:0rem;
}


.speaker_avatar {
    display: flex;
    flex-flow: row nowrap;
}

.sd_crop {
    width: 20%;
    max-width:7rem;
    overflow: hidden;
    position: relative;
    flex: 0 0 auto;
    aspect-ratio: 1/1;
    border-radius: 0.25rem;
    flex: 0 0 auto;
    box-shadow:0 0.5rem 5rem 1rem rgba(0,0,0,0.1);
}

    .sd_crop img {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

.sd_title {
    color: #fff;
    padding: 0.5rem 1rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
}

.sd_title > div:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 1.3rem;
    font-weight: 600;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;

}

.sd_title > div:nth-child(2) {
    font-size: 1rem;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.s_introduction{
    margin-top:1rem;
    color:#fff;
    text-align:justify;
    -webkit-hyphens:auto;
    hyphens:auto;
}

.video_slist{
    min-height:unset;
}


@media(min-width:768px) {
    .speaker_detail {
        width: 80%;
        margin: 0rem 0 1.5rem 0.5rem;
        padding: 0rem 1rem;
    }
    
}

@media(min-width:992px){
    .video_slist {
        min-height: 60vh;
    }
}