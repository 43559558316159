.speakers_list a {
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    border-radius:0.5rem;
    padding:0.25rem;
}

.speakers_list .li{
    padding:unset;
}




.s_crop {
    width: 20%;
    overflow: hidden;
    position: relative;
    flex: 0 0 auto;
    aspect-ratio: 1/1;
    border-radius: 0.25rem;
    flex: 0 0 auto;
}

.s_crop img{
    position:absolute;
    width:100%;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
}

.s_title{
    color:#fff;
    padding:0.5rem 0.5rem 0.5rem 1rem;
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    
}


    .s_title > p {
        margin: 0;
        line-height: 1.5rem;
    }

    .s_title > p:first-child {
        display: -webkit-box;
        margin-bottom:0.5rem;
        -webkit-box-orient: vertical;
        font-size: 1rem;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .s_title > p:nth-child(2) {
        
        font-size: 0.95rem;
        
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        overflow:hidden;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }



.speakers_list .nav-item {
    list-style: none;
    margin-bottom:0.25rem;
}

    .speakers_list li:hover {
        background: rgba(99, 145, 198,0.3);
        border-radius:0.25rem;
        color: #29476e
    }

.speakeractive {
    background: rgba(39, 70, 113,0.3);
}

    .speakers_list a {
        text-decoration: none;
        border-bottom: unset;
    }

    .speaker_container{
        position:relative;
    }


.s_speakers>div:first-child{
    width:100%;
}



@media(min-width:768px){
    .s_speakers>div:first-child{
        width:30%;
    }
    .s_crop{
        width:30%;
    }
}
